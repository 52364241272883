import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import colors from "../design/colors";
import keyShowIcon from "../../assets/password-show.svg";
import keyHideIcon from "../../assets/password-hide.svg";
import { JService } from "../api-service/ApiService";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { CalendarIcon } from "../../utility-functions/assetLoader";
import { enqueueSnackbar } from "notistack";
import { Button } from "@mui/material";
import ToggleFeature from "../UI/ToggleFeature";
import {
  CustomChip,
  CustomSelect,
  CustomSwitch,
} from "../design/components/customComponents";
import GModal from "../design/components/GModal";
import { DatePicker } from "rsuite";
import { getOrgURLParam } from "../../utility-functions";
import BlockListModal from "./BlockListModal";
import GButton from "../design/Button";
import { QRCodeSVG } from "qrcode.react";
import "./Account.css";

const API_BASE_URL = process.env.REACT_APP_BETA;

function AccountTab(props) {
  const { handleUpdateOrganization } = props;
  const reduxAccount = useSelector((state) => state?.account?.value);
  const {
    canManage2FA,
    canManagePrivateRecording,
    canManageRecordWarning,
    canManageCallDispositions,
    canManageOrgLevelIntegrations,
    canBlockCandidates,
  } = useSelector((state) => state?.auth?.permissions);
  const isOwner = useSelector((state) => state?.auth?.isOwner);
  const prevReduxOrg = useRef(reduxAccount?.selectedOrg);
  const field1Ref = useRef();
  const field2Ref = useRef();
  const field3Ref = useRef();
  const field4Ref = useRef();
  const field5Ref = useRef();
  const field6Ref = useRef();

  //States
  const [organization, setOrganization] = useState(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [controller, setController] = useState(0);

  const [newCallOutcome, setNewCallOutcome] = useState("");
  const [newCallReason, setNewCallReason] = useState("");
  const [reportScheduler, setReportScheduler] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [dailyReportTime, setDailyReportTime] = useState("");
  const [isBlockListModalVisible, setIsBlockListModalVisible] = useState(false);
  const [_2FAModalOpen, set2FAModalOpen] = useState(false);
  const [_2FAMethod, set2FAMethod] = useState(
    organization?.two_factor_auth_type === "TOTP"
      ? "Authenticator app"
      : organization?.two_factor_auth_type === "Email"
        ? "Email"
        : ""
  );
  const [showAuthenticatorScreen, setShowAuthenticatorScreen] = useState(false);
  const [showEmailScreen, setShowEmailScreen] = useState(false);
  const [codeValues, setCodeValues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });
  const [provisoningUrl, setProvisoningUrl] = useState("");
  const [change2FA, setChange2FA] = useState(false);

  //Handlers
  const handleAddNewOutcome = (e) => {
    if (!newCallOutcome) return;
    e.preventDefault();
    if (organization.call_outcomes.includes(newCallOutcome)) {
      enqueueSnackbar("Call Outcome already exists", { variant: "error" });
      return;
    }

    // Add check for account

    let updatedOutcomes = organization.call_outcomes.concat([newCallOutcome]);
    const updatedOrganization = {
      ...organization,
      call_outcomes: updatedOutcomes,
    };

    setOrganization(updatedOrganization);
    handleUpdateOrganization(updatedOrganization);
    setNewCallOutcome("");
  };

  const handleAddNewReason = (e) => {
    if (!newCallReason) return;
    e.preventDefault();
    if (organization.call_reasons.includes(newCallReason)) {
      enqueueSnackbar("Call reason already exists", { variant: "error" });
      return;
    }

    // Add check for account

    let updatedReasons = organization.call_reasons.concat([newCallReason]);
    const updatedOrganization = {
      ...organization,
      call_reasons: updatedReasons,
    };

    setOrganization(updatedOrganization);
    handleUpdateOrganization(updatedOrganization);
    setNewCallReason("");
  };

  const handleDeleteReason = (reason) => {
    if (Array.isArray(organization.call_reasons)) {
      const updatedReasons = organization.call_reasons.filter(
        (value) => value != reason
      );

      // Add check for account

      setOrganization({
        ...organization,
        call_reasons: updatedReasons,
      });
      handleUpdateOrganization({
        ...organization,
        call_reasons: updatedReasons,
      });
    }
  };

  const handleDeleteOutcome = (outcome) => {
    if (Array.isArray(organization.call_outcomes)) {
      const updatedOutcomes = organization.call_outcomes.filter(
        (value) => value != outcome
      );

      // Add check for account

      setOrganization({ ...organization, call_outcomes: updatedOutcomes });
      handleUpdateOrganization({
        ...organization,
        call_outcomes: updatedOutcomes,
      });
    }
  };

  const handleAddTag = (e) => {
    // check if tag is already added
    e.preventDefault();
    if (!tagValue) return;
    let itemIndex = allTags.findIndex((tag) => tag?.name === tagValue);
    if (itemIndex !== -1) {
      enqueueSnackbar("Tag already exists", { variant: "error" });
      return;
    }
    // tag not found in allTags
    // add-tag api call

    JService.post(`/api/v1/auth/tag/${getOrgURLParam()}`, {
      name: tagValue,
    })
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not create tag");
        // tag created
        enqueueSnackbar(res?.message, { variant: "success" });

        if (res?.name?.length && (res?.id || res?.id == 0))
          setAllTags((prev) => [...prev, { name: res?.name, id: res?.id }]);
        else setController((prev) => ++prev);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  const handleToggle = async (event, name) => {
    const value = event.target.checked;
    setOrganization((prevState) => {
      return { ...prevState, [name]: value };
    });
    const res = await handleUpdateOrganization({
      ...organization,
      [name]: value,
    });
    if (res && "success" in res && !res.success) {
      setOrganization(
        (prev) => res.organization || { ...prev, [name]: !value }
      );
    }
  };

  const handle2FAToggle = async (e, name) => {
    const value = e.target.checked;
    if (!value) {
      setOrganization((prevState) => {
        return {
          ...prevState,
          [name]: value,
          two_factor_auth_type: "NA",
        };
      });
      const res = await handleUpdateOrganization({
        ...organization,
        [name]: value,
        two_factor_auth_type: "NA",
      });
      if (res && "success" in res && !res.success) {
        setOrganization(
          (prev) => res.organization || { ...prev, [name]: !value }
        );
      }
    } else {
      set2FAModalOpen(true);
    }
  };

  const handleSaveChanges = () => {
    const updatedOrganization = {
      ...organization,
      daily_report_time: dailyReportTime,
    };

    handleUpdateOrganization(updatedOrganization)
      .then((res) => {
        if (res?.success) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setOrganization(updatedOrganization);
          setUnsavedChanges(false);
        }
        setReportScheduler(false);
      })
      .catch((err) => {
        console.error("Error saving changes:", err);
        enqueueSnackbar(err?.message, { variant: "error" });
      });
  };

  const handleTimeChange = (value) => {
    if (!(value instanceof Date)) {
      return;
    }
    const hours = value.getHours().toString().padStart(2, "0");
    const minutes = value.getMinutes().toString().padStart(2, "0");
    const selectedTime = `${hours}:${minutes}:00`;
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      daily_report_time: selectedTime,
    }));
    setDailyReportTime(selectedTime);
    setUnsavedChanges(true);
  };

  const handleTagDelete = (e, tagId) => {
    JService.delete(`/api/v1/auth/tag/?tag_id=${tagId}`)
      .then((res) => {
        // checking res.success === false since response is http 204 No content
        if (res?.success === false)
          throw new Error(res?.message || "Could not delete tag");
        enqueueSnackbar(res?.message || "Deleted tag", { variant: "success" });
        setController((prevState) => ++prevState);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  const close2FAModalHandler = () => {
    set2FAModalOpen(false);
    set2FAMethod(
      organization?.two_factor_auth_type === "TOTP"
        ? "Authenticator app"
        : organization?.two_factor_auth_type === "Email"
          ? "Email"
          : ""
    );
    setCodeValues({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "" });
    setShowAuthenticatorScreen(false);
    setShowEmailScreen(false);
    setChange2FA(false);
  };

  const _2FANextHandler = (method = null) => {
    const selectedMethod = method || _2FAMethod;
    if (selectedMethod === "Authenticator app") {
      JService.get(API_BASE_URL + `/api/v1/auth/setup-verify-totp/`)
        .then((response) => {
          if (!response?.success)
            throw new Error(response?.message || "Failed to retrieve QR code");
          setProvisoningUrl(response?.provisioning_uri);
        })
        .catch((error) => {
          console.error(error.response);
          enqueueSnackbar(error?.message, { variant: "error" });
        });
      setShowAuthenticatorScreen(true);
    } else if (selectedMethod === "Email") {
      JService.post(API_BASE_URL + `/api/v1/auth/send-code/`, {
        email: reduxAccount?.data?.email,
        source: "verification",
      })
        .then((response) => {
          if (!response?.success)
            throw new Error(
              response?.message || "Error occured while sending OTP"
            );
          enqueueSnackbar(
            "Check for the verification code in your email" +
              ". Look in " +
              reduxAccount?.data?.email,
            { variant: "success" }
          );
        })
        .catch((error) => {
          console.error(error.response);
          enqueueSnackbar(error?.message || "Error occured while sending OTP", {
            variant: "error",
          });
        });
      setShowEmailScreen(true);
    }
  };

  const handleCodeChange = (e) => {
    const field = e.target.dataset.loginscreen2;
    const value = e.target.value;

    if (value === " ") return;

    const mapping = {
      1: {
        ondeleteFocus: field1Ref,
        oninsertFocus: field2Ref,
        selfFocus: field1Ref,
      },
      2: {
        ondeleteFocus: field1Ref,
        oninsertFocus: field3Ref,
        selfFocus: field2Ref,
      },
      3: {
        ondeleteFocus: field2Ref,
        oninsertFocus: field4Ref,
        selfFocus: field3Ref,
      },
      4: {
        ondeleteFocus: field3Ref,
        oninsertFocus: field5Ref,
        selfFocus: field4Ref,
      },
      5: {
        ondeleteFocus: field4Ref,
        oninsertFocus: field6Ref,
        selfFocus: field5Ref,
      },
      6: {
        ondeleteFocus: field5Ref,
        oninsertFocus: field6Ref,
        selfFocus: field6Ref,
      },
    };

    const inputType = e.nativeEvent.inputType;

    if (inputType === "deleteContentBackward") {
      mapping[field].ondeleteFocus.current.focus();
    } else if (inputType === "deleteContentForward") {
      mapping[field].oninsertFocus.current.focus();
    } else if (value || value === 0) {
      mapping[field].oninsertFocus.current.focus();
    }

    const update = { ...codeValues, [field]: value.toUpperCase() };
    setCodeValues(update);
  };

  function listenToPasteEvent(e) {
    const fromClipboard = (e.clipboardData || window.clipboardData)
      .getData("text")
      ?.toUpperCase();
    const values = structuredClone(codeValues);
    Array.from(fromClipboard).forEach((c, index) => {
      values[index + 1] = c;
    });
    setCodeValues(values);
  }

  const submit2FAHandler = () => {
    const codeValue = Object.values(codeValues).join("");
    if (codeValue.length !== 6) return;

    if (_2FAMethod === "Authenticator app") {
      JService.post(
        API_BASE_URL + "/api/v1/auth/setup-verify-totp/?webapp=True",
        {
          otp: codeValue,
          email: reduxAccount?.data?.email,
          is_newly_created: true,
        }
      )
        .then(async (response) => {
          if (!response?.success) {
            throw new Error(response?.message || "Could not verify TOTP.");
          }
          setOrganization((prevState) => {
            return {
              ...prevState,
              two_factor_auth_enabled: true,
              two_factor_auth_type: "TOTP",
            };
          });
          const res = await handleUpdateOrganization({
            ...organization,
            two_factor_auth_enabled: true,
            two_factor_auth_type: "TOTP",
          });
          if (res && "success" in res && !res.success) {
            setOrganization(
              (prev) =>
                res.organization || { ...prev, two_factor_auth_enabled: false }
            );
          }
          enqueueSnackbar(response?.message, { variant: "success" });
        })
        .catch((error) =>
          enqueueSnackbar(error?.message, { variant: "error" })
        );
    } else if (_2FAMethod === "Email") {
      JService.post(API_BASE_URL + "/api/v1/auth/validate-code/?webapp=True", {
        email: reduxAccount?.data?.email,
        code: codeValue,
        source: "login",
      })
        .then(async (response) => {
          if (!response?.success) {
            throw new Error(response?.message || "Could not verify OTP.");
          }
          setOrganization((prevState) => {
            return {
              ...prevState,
              two_factor_auth_enabled: true,
              two_factor_auth_type: "Email",
            };
          });
          const res = await handleUpdateOrganization({
            ...organization,
            two_factor_auth_enabled: true,
            two_factor_auth_type: "Email",
          });
          if (res && "success" in res && !res.success) {
            setOrganization(
              (prev) =>
                res.organization || { ...prev, two_factor_auth_enabled: false }
            );
          }
          enqueueSnackbar(response?.message, { variant: "success" });
        })
        .catch((error) =>
          enqueueSnackbar(error?.message, { variant: "error" })
        );
    }

    close2FAModalHandler();
  };

  const change2FAMethodHandler = (e) => {
    const method = e.target.value;
    set2FAMethod(method);
    set2FAModalOpen(true);
    setChange2FA(true);
    if (method === "Authenticator app") {
      setShowAuthenticatorScreen(true);
    } else if (method === "Email") {
      setShowEmailScreen(true);
    }
  };

  const continueChange2FAMethod = () => {
    setChange2FA(false);
    if (_2FAMethod === "Authenticator app") {
      _2FANextHandler("Authenticator app");
    } else if (_2FAMethod === "Email") {
      _2FANextHandler("Email");
    }
  };

  //Effects

  useEffect(() => {
    set2FAMethod(
      organization?.two_factor_auth_type === "TOTP"
        ? "Authenticator app"
        : organization?.two_factor_auth_type === "Email"
          ? "Email"
          : ""
    );
  }, [organization]);

  useEffect(() => {
    field1Ref?.current?.focus();
    document.addEventListener("paste", listenToPasteEvent);
    return () => document.removeEventListener("paste", listenToPasteEvent);
  }, []);

  useEffect(() => {
    if (props.organization) {
      setOrganization(props.organization);
    }
  }, [props.organization]);

  useEffect(() => {
    setTagValue("");

    JService.get(`/api/v1/auth/retrieve-tags/${getOrgURLParam()}`)
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive tags");
        setAllTags(res?.data?.map((rd) => ({ name: rd?.name, id: rd?.id })));
      })
      .catch((err) => console.warn(err?.message || "Failed to fetch tags"));
  }, [controller]);

  useEffect(() => {
    // reduxAccount changed
    if (
      prevReduxOrg.current !== reduxAccount?.selectedOrg &&
      (Boolean(reduxAccount?.selectedOrg) || reduxAccount?.selectedOrg === 0)
    ) {
      // setorgdetails again if allOrgs?.length > 1 && primaryAccount holder
      prevReduxOrg.current = "";
      // reduxOrg.selectedOrg
      setController((prevState) => ++prevState);
    }
  }, [reduxAccount]);

  const reportSchedulerBody = reportScheduler ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        {/* name */}
        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          Frequency
          <input
            readOnly={true}
            type="text"
            className="t6 regular-font nc-gray-900"
            style={{
              marginTop: "4px",
              padding: "8px 12px",
              borderRadius: "4px",
              border: "1px solid #E1E6E2",
            }}
            value={"Daily"}
            data-edittype="name"
          />
        </label>

        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          <label
            className="t7 nc-gray-900 medium-font"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            Time
            <label
              className="t7 nc-gray-900 medium-font"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <div style={{ width: "100%" }}>
                <DatePicker
                  format="HH:mm"
                  style={{ width: "100%" }}
                  onChange={(value) => handleTimeChange(value)}
                  ranges={[]}
                  cleanable={false}
                  value={
                    organization.daily_report_time
                      ? new Date(`2000-01-01T${organization.daily_report_time}`)
                      : null
                  }
                  hideMinutes={(minute) => minute % 5 !== 0}
                />
              </div>
            </label>
          </label>
        </label>
      </form>
    </Box>
  ) : null;

  const saveButtonStyle = {
    backgroundColor: unsavedChanges ? "#34CB65" : "#BDBDBD",
    color: "white",
    width: "65px",
    height: "36px",
    "&:hover": {
      backgroundColor: unsavedChanges ? "#4CAF50" : "#BDBDBD",
    },
    "&:disabled": {
      backgroundColor: "#F5F7F6",
    },
  };

  const openBlockListModal = () => setIsBlockListModalVisible(true);
  const handleBlockListModalClose = () => setIsBlockListModalVisible(false);

  const codeBoxes = (
    <div className="code-container">
      <input
        ref={field1Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="1"
        onChange={handleCodeChange}
        value={codeValues[1]}
      />
      <input
        ref={field2Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="2"
        onChange={handleCodeChange}
        value={codeValues[2]}
      />
      <input
        ref={field3Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="3"
        onChange={handleCodeChange}
        value={codeValues[3]}
      />
      <input
        ref={field4Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="4"
        onChange={handleCodeChange}
        value={codeValues[4]}
      />
      <input
        ref={field5Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="5"
        onChange={handleCodeChange}
        value={codeValues[5]}
      />
      <input
        ref={field6Ref}
        type="text"
        maxLength="1"
        autoComplete="none"
        data-loginscreen2="6"
        onChange={handleCodeChange}
        value={codeValues[6]}
      />
    </div>
  );

  return (
    <>
      {organization ? (
        <div className="general-tab">
          {canManageCallDispositions && (
            <>
              <div
                className="section dropdown-section"
                style={{ marginBottom: "40px" }}
              >
                <div className="t5 medium-font nc-gray-900">
                  Call attributes
                </div>

                <label>Call reason</label>
                <Box>
                  <form onSubmit={handleAddNewReason}>
                    <Box sx={{ ...localStyles.inputContainer, width: "360px" }}>
                      <input
                        placeholder="Type and press Enter to create"
                        style={localStyles.input}
                        onChange={(e) => setNewCallReason(e.target.value)}
                        onSubmit={handleAddNewReason}
                        value={newCallReason}
                        className="regular-font t6 nc-gray-900 disabled-input"
                        disabled={organization?.call_reasons?.length > 9}
                      />
                    </Box>
                  </form>

                  <Box sx={{ ...localStyles.chipContainer, width: "560px" }}>
                    {/* the display */}
                    {organization.call_reasons?.map((reason, index) => (
                      <CustomChip
                        key={index}
                        label={reason}
                        onDelete={(e) => handleDeleteReason(reason)}
                        style={localStyles.chip}
                      />
                    ))}
                  </Box>
                </Box>
                <label>Call outcome</label>
                <Box>
                  <form onSubmit={handleAddNewOutcome}>
                    <Box sx={{ ...localStyles.inputContainer, width: "360px" }}>
                      <input
                        placeholder="Type and press Enter to create"
                        style={localStyles.input}
                        onChange={(e) => setNewCallOutcome(e.target.value)}
                        onSubmit={handleAddNewOutcome}
                        value={newCallOutcome}
                        className="regular-font t6 nc-gray-900 disabled-input"
                        disabled={organization?.call_outcomes?.length >= 15}
                      />
                    </Box>
                  </form>

                  <Box sx={{ ...localStyles.chipContainer, width: "560px" }}>
                    {/* the display */}
                    {organization.call_outcomes?.map((outcome, index) => (
                      <CustomChip
                        key={index}
                        label={outcome}
                        onDelete={(e) => handleDeleteOutcome(outcome)}
                        style={localStyles.chip}
                      />
                    ))}
                  </Box>
                </Box>
                <label>Tags</label>
                <Box>
                  <form onSubmit={handleAddTag}>
                    <Box sx={{ ...localStyles.inputContainer, width: "360px" }}>
                      <input
                        placeholder="Type and press Enter to create"
                        style={localStyles.input}
                        onChange={(e) => setTagValue(e.target.value)}
                        onSubmit={handleAddTag}
                        value={tagValue}
                        className="regular-font t6 nc-gray-900"
                        onClick={handleAddTag}
                      />
                    </Box>
                  </form>

                  <Box sx={{ ...localStyles.chipContainer, width: "560px" }}>
                    {/* the display */}
                    {allTags?.map((tag, index) => (
                      <CustomChip
                        key={index}
                        label={tag?.name}
                        onDelete={(e) => handleTagDelete(e, tag?.id)}
                        style={localStyles.chip}
                      />
                    ))}
                  </Box>
                </Box>
              </div>

              <div className="settings-sep"></div>
            </>
          )}

          {(isOwner || canManageRecordWarning) && (
            <>
              <div className="section">
                <div className="t5 medium-font nc-gray-900">Notifications</div>
                {isOwner && (
                  <label className="switch" style={{ marginTop: "32px" }}>
                    <input
                      type="checkbox"
                      checked={organization.daily_reports}
                      onChange={(e) => handleToggle(e, "daily_reports")}
                      style={{ cursor: "pointer", marginTop: "0" }}
                    />
                    <span className="slider round"></span>
                    <p
                      style={{
                        marginTop: "-45px",
                        marginLeft: "60px",
                        width: "400px",
                      }}
                      className="t6 regular-font nc-gray-900"
                    >
                      Call reports
                    </p>
                    <div
                      className="t6 regular-font nc-gray-600"
                      style={{
                        width: "340px",
                        marginLeft: "60px",
                        marginTop: "0",
                      }}
                    >
                      Get daily call reports through email
                      <div>
                        <CalendarIcon /> Daily ⋅{" "}
                        {props.organization.daily_report_time
                          ? props.organization.daily_report_time.slice(0, 5)
                          : "Not set"}{" "}
                        (IST)
                        <button
                          size="small"
                          style={{
                            color: "#28994B",
                            marginLeft: "12px",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setReportScheduler(true);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      <GModal
                        visible={reportScheduler}
                        closeModal={() => {
                          setReportScheduler(false);
                          setUnsavedChanges(false);
                        }}
                        heading={"Report scheduler"}
                        body={reportSchedulerBody}
                        modalStyle={{
                          height: "200px",
                          width: "368px",
                          padding: "32px, 24px, 24px, 24px",
                        }}
                        bottomButtons={[
                          <Button
                            onClick={handleSaveChanges}
                            disabled={!unsavedChanges}
                            sx={saveButtonStyle}
                          >
                            Save
                          </Button>,
                        ]}
                      />
                    </div>
                  </label>
                )}
                <br />
                {canManageRecordWarning && (
                  <label className="switch" style={{ marginTop: "34px" }}>
                    <input
                      type="checkbox"
                      checked={organization.record_warning}
                      onChange={(e) => handleToggle(e, "record_warning")}
                      style={{ cursor: "pointer", marginTop: "0" }}
                    />
                    <span className="slider round"></span>
                    <p
                      style={{
                        marginTop: "-45px",
                        marginLeft: "60px",
                        width: "400px",
                      }}
                      className="t6 regular-font nc-gray-900"
                    >
                      Call recording announcement
                    </p>
                    <p
                      className="t6 regular-font nc-gray-600"
                      style={{
                        width: "340px",
                        marginLeft: "60px",
                        marginTop: "0",
                      }}
                    >
                      Notify both the caller and recipient that the call is
                      being recorded.
                    </p>
                  </label>
                )}
              </div>
              <div
                style={{
                  marginTop: "78px",
                  marginBottom: "30px",
                  width: "100%",
                }}
                className="settings-sep"
              ></div>
            </>
          )}

          {(canManage2FA || canManagePrivateRecording) && (
            <>
              <div className="section">
                {canManagePrivateRecording && (
                  <>
                    <div className="t5 medium-font nc-gray-900">
                      Privacy & Security
                    </div>
                    <label className="switch" style={{ marginTop: "32px" }}>
                      <input
                        type="checkbox"
                        checked={organization.private_recording}
                        onChange={(e) => handleToggle(e, "private_recording")}
                        style={{ cursor: "pointer", marginTop: "0" }}
                      />
                      <span className="slider round"></span>
                      <p
                        style={{
                          marginTop: "-45px",
                          marginLeft: "60px",
                          width: "400px",
                        }}
                        className="t6 regular-font nc-gray-900"
                      >
                        Private Recording
                      </p>
                      <p
                        className="t6 regular-font nc-gray-600"
                        style={{
                          width: "340px",
                          marginLeft: "60px",
                          marginTop: "0",
                        }}
                      >
                        Sharable recording links would be private
                      </p>
                    </label>
                  </>
                )}
                {canManage2FA && (
                  <>
                    <label className="switch" style={{ marginTop: "48px" }}>
                      <input
                        type="checkbox"
                        checked={organization.two_factor_auth_enabled}
                        onChange={(e) =>
                          handle2FAToggle(e, "two_factor_auth_enabled")
                        }
                        style={{ cursor: "pointer", marginTop: "0" }}
                      />
                      <span className="slider round"></span>
                      <p
                        style={{
                          marginTop: "-45px",
                          marginLeft: "60px",
                          width: "400px",
                        }}
                        className="t6 regular-font nc-gray-900"
                      >
                        Two factor authentication
                      </p>
                      <p
                        className="t6 regular-font nc-gray-600"
                        style={{
                          width: "500px",
                          marginLeft: "60px",
                          marginTop: "0",
                        }}
                      >
                        Make 2FA as default security verification method for all
                        users
                      </p>
                    </label>
                    <GModal
                      visible={_2FAModalOpen}
                      closeModal={close2FAModalHandler}
                      heading={"Set up 2FA"}
                      body={
                        showAuthenticatorScreen && change2FA ? (
                          <div className="authenticator-screen">
                            <p style={{ margin: 0 }}>
                              Setting up authenticator app verification requires
                              scanning a QR code and configuring your app.{" "}
                              <br /> <br /> Your current verification method
                              will be disabled. Do you want to continue?
                            </p>
                          </div>
                        ) : showAuthenticatorScreen ? (
                          <div className="authenticator-screen">
                            {provisoningUrl && (
                              <QRCodeSVG value={provisoningUrl} size={149} />
                            )}
                            <p>
                              Scan the QR code with your authenticator app and
                              enter the verification code below
                            </p>
                            {codeBoxes}
                          </div>
                        ) : showEmailScreen && change2FA ? (
                          <div className="email-screen">
                            <p style={{ margin: 0 }}>
                              Switching to email verification will send security
                              codes to your email address{" "}
                              <span>{reduxAccount?.data?.email}.</span> <br />
                              <br />
                              Your current verification method will be disabled.
                              Do you want to continue?
                            </p>
                          </div>
                        ) : showEmailScreen ? (
                          <div className="email-screen">
                            <p>
                              Enter the verification code that was sent to your
                              email
                            </p>
                            {codeBoxes}
                          </div>
                        ) : (
                          <CustomSelect
                            label={
                              "Select method for two-factor authentication"
                            }
                            labelStyles={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: "500",
                              color: "#3F3F46",
                            }}
                            value={_2FAMethod}
                            handleChange={(e) => set2FAMethod(e.target.value)}
                            defaultValue={"Select"}
                            options={[
                              {
                                value: "Authenticator app",
                                displayValue: "Authenticator app",
                              },
                              { value: "Email", displayValue: "Email" },
                            ]}
                            selectStyles={{ marginTop: "4px" }}
                            defaultValStyles={{ color: "#A0A0AB" }}
                          />
                        )
                      }
                      modalStyle={{
                        height: change2FA
                          ? "100%"
                          : showAuthenticatorScreen
                            ? "341px"
                            : showEmailScreen
                              ? "140px"
                              : "124px",
                        width: "448px",
                        padding: "32px, 24px, 32px, 24px",
                      }}
                      bottomButtons={[
                        <GButton
                          buttonText={"Cancel"}
                          hierarchy={"white"}
                          variant={"outlined"}
                          onClick={close2FAModalHandler}
                        />,
                        change2FA &&
                        (showAuthenticatorScreen || showEmailScreen) ? (
                          <GButton
                            buttonText={"Continue"}
                            hierarchy={"green"}
                            onClick={continueChange2FAMethod}
                          />
                        ) : showAuthenticatorScreen || showEmailScreen ? (
                          <GButton
                            buttonText={"Submit"}
                            hierarchy={"green"}
                            disabled={
                              Object.values(codeValues).join("")?.length < 6
                            }
                            onClick={submit2FAHandler}
                          />
                        ) : (
                          <GButton
                            buttonText={"Next"}
                            hierarchy={"green"}
                            disabled={!_2FAMethod}
                            onClick={() => _2FANextHandler(null)}
                          />
                        ),
                      ]}
                    />
                  </>
                )}
                {canManage2FA && organization.two_factor_auth_enabled && (
                  <CustomSelect
                    value={_2FAMethod}
                    handleChange={(e) => change2FAMethodHandler(e)}
                    defaultValue={"Select"}
                    options={[
                      {
                        value: "Authenticator app",
                        displayValue: "Authenticator app",
                      },
                      { value: "Email", displayValue: "Email" },
                    ]}
                    selectStyles={{ marginTop: "6px", width: "320px" }}
                    defaultValStyles={{ color: "#A0A0AB" }}
                  />
                )}
              </div>
              <div style={{ marginTop: "78px" }} className="settings-sep"></div>
            </>
          )}

          {canBlockCandidates ? (
            <div
              className="section t6 regular-font nc-gray-900"
              style={{ marginBottom: 40 }}
            >
              <div className="medium-font t5">Block list</div>
              <p className="t6" style={{ marginBlock: 24, marginTop: 32 }}>
                Block contacts/numbers from calling or messaging you.
              </p>
              <GButton
                variant="outlined"
                buttonText="Manage block list"
                icon={{}}
                isLoading={false}
                hierarchy="white"
                disabled={false}
                onClick={openBlockListModal}
              />
            </div>
          ) : null}
          <BlockListModal
            isVisible={isBlockListModalVisible}
            handleClose={handleBlockListModalClose}
          />
          <div className="settings-sep"></div>

          {canManageOrgLevelIntegrations && (
            <div className="section api-section">
              <div className="t5 medium-font nc-gray-900">API Key</div>
              <div style={{ display: "flex", marginTop: "40px" }}>
                <div className="text-box">
                  <input
                    readOnly
                    value={props.apiKey}
                    placeholder="Click the button to create API key"
                    type={showApiKey ? "text" : "password"}
                  />
                  {!!props.apiKey && (
                    <button
                      className="show-hide-btn"
                      onClick={() => setShowApiKey(!showApiKey)}
                    >
                      <img src={showApiKey ? keyHideIcon : keyShowIcon} />
                    </button>
                  )}
                </div>
                <button
                  className="copy-btn"
                  onClick={() => {
                    if (props?.apiKey) props.handleCopyApiKey();
                    else props.handleGenerateApiKey();
                  }}
                >
                  {!!props.apiKey ? "Copy" : "Create"}
                </button>
              </div>
              <p className="t6 regular-font nc-gray-900 api-key-description">
                This page provides background information on API keys and
                authentication: how each of these are used, the differences
                between them, and the scenarios where you should consider using
                API keys.&nbsp;
                <a href="https://frejun.com/privacy">Read our Privacy Policy</a>
              </p>
            </div>
          )}
        </div>
      ) : (
        <AnimationPage />
      )}
    </>
  );
}

export default AccountTab;

const localStyles = {
  inputContainer: {
    width: "400px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    width: "100%",
    padding: "8px 12px",
    border: `1px solid ${colors.nc_gray_300}`,
    height: "40px",
    borderRadius: "0px 4px 4px 0px",
    marginBottom: "8px",
  },
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: "400px",
  },
  chip: {
    marginBottom: "4px",
  },
};
